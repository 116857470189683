.App {
  text-align: center;
}

.row-banner {
  margin-top: 10px;
}

.row-info {
  margin-top: 20px;
}

.row-footer {
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
}

.link_copyright {
  color: black;
  text-decoration: none;
}
